<template>
    <div v-if="ready" class="fill-height">
        <subpage-top-bar>Таблица выгруженного контента</subpage-top-bar>
    
        <v-container fluid class="fill-height pt-0 pb-0">
            <v-row
                justify="start"
                align="stretch"
                class="fill-height overflow-hidden"
            >
                <v-col v-if="ready" :cols="12" class="fill-height overflow-auto">
                    <v-simple-table>
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    ExternalId
                                </th>
                                <th class="text-left">
                                    Название
                                </th>
                                <th class="text-left">
                                    Верифицирован
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="item in items"
                                :key="item.externalId"
                            >
                                <td>{{ item.externalId }}</td>
                                <td>{{ item.courseName }}</td>
                                <td>{{ item.approved ? 'Да' : 'Нет' }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-container>
    </div>
    <!-- Loading icon -->
    <div
        v-else
        class="
            wv-100
            vh-100
            fill-height
            d-flex
            flex-row
            justify-center
            align-center
        "
    >
        <v-progress-circular
            :size="70"
            :width="7"
            color="purple darken-3"
            indeterminate
        />
    </div>
</template>
<script>
import SubpageTopBar from '@/components/template/SubpageTopBar'

export default {
    name: "Main",
    components: {
        SubpageTopBar
    },
    data () {
        return {
            ready: false,
            loading: false,
            items: []
        };
    },
    methods: {
        async fetch() {
            this.loading = true;
            const { data } = await this.$store.dispatch('app/syncTable');
            this.items = data.items;
            this.loading = false;
        }
    },
    async created() {
        // Fetch data 
        await this.fetch();
        // Ready
        this.ready = true;
    }
};
</script>